import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "gatsby";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";

const { Title } = Typography;

const RestautantService = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={5}>EFFECTIVE AS OF December 16th, 2019.</Title>
              <Title level={1}>SpotOn Restaurant Service Description</Title>
              <Title level={3}>Restaurant Creation Terms</Title>
              <p>
                SpotOn Restaurant is a full-scale point-of-sale system built
                specifically for restaurants, and customized to fit and grow
                with your business. Your SpotOn implementation fee includes
                onsite installation along with remote system configuration,
                staff training, and go-live support.
              </p>
              <p>
                During the menu build and system configuration process, our team
                will be in close communication with you to build your custom
                menu and configure your POS system to fit your specific
                requirements, with as many users and software integrations as
                you need.
              </p>
              <Title level={3}>Restaurant Installation Terms</Title>
              <p>
                Onsite installations are performed Monday through Thursday,
                between the hours of 7:30am and 7:00pm, Eastern Standard Time.
                Any onsite installation work performed outside standard
                installation hours will incur additional service fees.
              </p>
              <p>
                Menu Verification and Site Readiness are crucial components to a
                successful system installation. You are responsible for
                completing a Menu Verification call and confirming how the menu
                is built before we ship out the system. Additionally, you are
                responsible for ensuring your site is ready for installation
                before SpotOn technicians arrive onsite. If our technicians
                arrive onsite and are unable to install the system due to lack
                of site readiness, you will incur additional service fees.
              </p>
              <p>
                Cabling costs are not included in your implementation fee and
                must be completed as part of your site readiness preparations.
                SpotOn does not perform cabling services, but we can help
                identify a local provider, upon request.{" "}
              </p>
              <Title level={3}>
                Restaurant Installation Cancellation Terms
              </Title>
              <p>
                Once installation and go-live dates are scheduled, there is a
                seven (7) day cancellation window. Canceling within seven (7)
                days of the onsite service appointment will result in a service
                fee for each previously scheduled installation day.
              </p>
              <Title id="disclosure" level={3}>
                Cisco Meraki Router and Data Usage Terms{" "}
              </Title>
              <p>
                Cisco Meraki routers and LTE data access service plans are
                provided for SpotOn Restaurant usage only. Cisco Meraki routers
                shall not be tempered with or used for personal use. Personal
                use includes, but is not limited to, video streaming and
                internet browsing. Additionally, the use of external devices not
                provided by SpotOn, such as personal routers and computers, is
                prohibited.
              </p>
              <p>
                SpotOn will provide one (1) gigabyte of LTE access each calendar
                month, to be used as backup connectivity for SpotOn Restaurant
                in the event that internet access is limited or disrupted at the
                business location. LTE backup data is not intended to replace
                your internet service provider, and may not be used for personal
                use or as a wireless guest network. Personal use includes, but
                is not limited to, video streaming and internet browsing. In the
                event where backup data usage exceeds one gigabyte in a period
                of one month, SpotOn has the right to charge for data overages
                at a rate of $15 per additional gigabyte or 1.46 cents per
                additional megabyte.
              </p>
              <p>
                Misuse of Cisco Meraki router and backup LTE data will result in
                merchant assuming full liability for data breaches, computer
                viruses, replacement equipment costs, and any other unintended
                consequences.
              </p>
              <Title level={3}>
                Restaurant Ongoing Technical Support Terms
              </Title>
              <p>
                Once your Restaurant POS has been fully implemented, we will
                continue to provide unlimited 24/7 phone support at no cost.
                Restaurant POS is designed with flexible software that is easy
                to configure, and includes automatic, cloud-based software
                updates.
              </p>
              <Title level={3}>Standard Restaurant POS Fees</Title>
              <p>
                SpotOn restaurant fees are automatically debited from your
                account according to the timeline outlined below; we will never
                ask you to make a payment through cash or personal check.{" "}
              </p>
              <p>
                An initial deposit of $400 is charged upon your application’s
                approval by the SpotOn Underwriting team. This deposit is fully
                refundable up until your installation date, and is deducted from
                the final cost of your Restaurant POS hardware. The remaining
                hardware costs will be charged on your installation date, along
                with your implementation fee, shipping &amp; handling costs and
                sales taxes.{" "}
              </p>
              <p>
                The monthly POS service fee includes software licensing fee,
                software updates and 24/7 technical support. Your monthly POS
                service fees will be automatically deducted at the beginning of
                each month following your installation date.
              </p>
              <Title level={3}>
                Restaurant POS Contract &amp; Cancellation Terms
              </Title>
              <p>
                Your Restaurant POS and processing agreement is on a
                month-to-month contract that you may cancel at any time. If you
                wish to cancel your Restaurant POS contract with SpotOn, you can
                request cancellation by emailing{" "}
                <a href="mailto:restaurant@spoton.com">restaurant@spoton.com</a>{" "}
                or calling us during regular business hours at 877-814-4102
                anytime Monday-Friday between 9am and 6pm EST.{" "}
              </p>
              <p>
                Should you cancel your SpotOn Restaurant relationship within one
                year of installation, you will be required to pay SpotOn the
                difference between your initial purchase amount and the full
                List Price of all hardware purchased.
              </p>
              <p>
                Should you cancel your SpotOn Restaurant relationship after one
                year of installation, you own the equipment and therefore are
                just required to give notification.
              </p>
              <p>
                Should you wish to continue to use your SpotOn Restaurant
                system, but partner with a different payment processor, you will
                pay a $995 conversion fee and your software license costs will
                double.
              </p>
              <Title level={3}>Limited SpotOn Restaurant System Warranty</Title>
              <p>
                This Limited SpotOn Restaurant System Warranty applies only to
                SpotOn Restaurant systems You purchase for internal use, and not
                for resale.
              </p>
              <p>
                (a) SpotOn warrants the hardware components of the SpotOn
                Restaurant systems shall be free from material defects in
                design, materials, and workmanship, and will function, under
                normal use, in accordance with the documentation, for one (1)
                year from the SpotOn Restaurant systems shipment date (“SpotOn
                Restaurant System Warranty Period”).
              </p>
              <p>
                (b) In the event You report to SpotOn, during the SpotOn
                Restaurant System Warranty Period, the SpotOn Restaurant system
                does not materially conform with the documentation under normal
                use, and such non-conformity is reproducible and verifiable,
                SpotOn, at its discretion, shall either (1) repair or replace
                the SpotOn Restaurant system, or (2) refund fees received for
                the non-conforming SpotOn Restaurant system, prorated for the
                use period.
              </p>
              <p>
                (c) All replacement parts furnished to You under this Limited
                SpotOn Restaurant System Warranty may be new or refurbished, and
                warranted as new for the remainder of the SpotOn Restaurant
                System Warranty Period.
              </p>
              <p>
                (d) All component parts, which have been replaced, becomes
                SpotOn property. All component parts that have been repaired
                remains Your property.
              </p>
              <p>
                (e) Return or Replacement Procedures. If a SpotOn Restaurant
                system or component part does not function as warranted during
                the SpotOn Restaurant System Warranty Period, and such
                non-conformity is reproducible and verifiable, You shall comply
                with SpotOn’s return or replacement procedures, including
                without limitation: (1) obtain SpotOn’s prior written approval;
                (2) present a proof of purchase and certificate of origin; (3)
                provide SpotOn the SpotOn Restaurant system’s identification
                number; (4) remove all features, parts, options, alterations,
                data, configuration settings, programs, and attachments not
                under warranty or provided by SpotOn; (5) ensure the SpotOn
                Restaurant system is free of legal obligations or restrictions
                that prevent exchange, including SpotOn Restaurant system owner
                authorization to have SpotOn service the SpotOn Restaurant
                system; (7) backup and secure all programs and data in the
                SpotOn Restaurant system; and (8) inform SpotOn of the SpotOn
                Restaurant system physical location. In the event SpotOn must
                repair or replace a SpotOn Restaurant system at Your premises,
                You agree to provide SpotOn sufficient work space and safe
                access to facilities.
              </p>
              <p>
                (f) Transportation costs incurred in connection with the return
                of a SpotOn Restaurant system to SpotOn shall be borne by
                SpotOn. You agree to ship the SpotOn Restaurant system or
                component part suitably packaged according to SpotOn guidelines
                to the SpotOn designated location. Transportation costs incurred
                in connection with the redelivery of a repaired or replacement
                SpotOn Restaurant system or component part to You by SpotOn
                shall be borne by SpotOn; provided, however, that if SpotOn
                determines, in its sole discretion, that the SpotOn Restaurant
                system is not covered by this Limited SpotOn Restaurant System
                Warranty or made after the SpotOn Restaurant System Warranty
                Period, the repair or replacement costs, including shipping,
                shall be paid by You, and SpotOn shall have no obligation to
                deliver the repaired or replaced SpotOn Restaurant system or
                component part to You until You provide SpotOn Your shipping
                carrier and account.
              </p>
              <p>
                (g) You acknowledge that in order to perform this Limited SpotOn
                Restaurant System Warranty, SpotOn may ship all or part of the
                SpotOn Restaurant system to third parties located anywhere in
                the world, and You authorize SpotOn to do so.
              </p>
              <p>
                (h) SpotOn Restaurant System Warranty Exclusions. The Limited
                SpotOn Restaurant System Warranty shall be void as to SpotOn
                Restaurant systems damaged or rendered unserviceable by: (1)
                improper or inadequate maintenance by anyone other than SpotOn
                or SpotOn authorized employees or independent contractors
                (“Representatives”); (2) software or interfacing supplied by
                anyone other than SpotOn; (3) modifications, alterations,
                repairs, installations, openings, or additions to the SpotOn
                Restaurant systems by anyone not certified by SpotOn or SpotOn
                authorized Representatives; (4) negligence by any person other
                than SpotOn or SpotOn’s authorized Representatives; (5) misuse,
                abuse, accident, electrical irregularity, theft, vandalism,
                water, or Force Majeure Event; (6) damage caused by containment
                and/or operation outside the environmental specifications for
                the SpotOn Restaurant systems; (7) alteration or connection of
                the SpotOn Restaurant systems to systems, equipment, or devices
                (other than those SpotOn specifically approved) without SpotOn
                prior approval; (8) governmental actions or inactions; (9)
                strikes or work stoppages; (10) Your failure to follow
                documentation, including handling or storage not according to
                SpotOn guidelines; or (11) repair or replacement contrary to
                Applicable Laws. “Force Majeure Event” means cause beyond the
                reasonable control of an affected party (including, without
                limitation, pandemic (e.g. COVID-19), war, wind, natural
                disaster, lightning, fire, earthquake, flood, hurricane, riots,
                acts of God, Internet service provider failures or delays,
                denial of Internet service attacks). “Applicable Laws” means
                applicable national, federal, state, and local laws, rules,
                guidelines, court or government agency orders, and regulations.
              </p>
              <p>
                (i) The SpotOn Restaurant System Warranty Period is not extended
                when SpotOn repairs or replaces a SpotOn Restaurant system or
                component part.
              </p>
              <p>
                SpotOn, at its discretion, may change the availability of the
                Limited SpotOn Restaurant System Warranty, but changes will not
                be retroactive, or affect existing signed ordering documents.
              </p>
              <p>
                <u>Disclaimer of Warranties.</u> EXCEPT AS PROVIDED IN THIS
                LIMITED SPOTON RESTAURANT SYSTEM WARRANTY, SPOTON MAKES NO (AND
                SPOTON SPECIFICALLY DISCLAIMS ALL) REPRESENTATIONS, WARRANTIES
                OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY
                OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, REPRESENTATIONS,
                WARRANTIES OR CONDITIONS: (A) ARISING OUT OF ANY COURSE OF
                PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE; (B) OF: (I)
                SATISFACTORY QUALITY; (II) FITNESS FOR A PARTICULAR PURPOSE;
                (III) NON-INFRINGEMENT; OR (IV) INTEROPERABILITY WITH
                THIRD-PARTY PRODUCTS OR SERVICES; AND (C) THAT THE SPOTON
                RESTAURANT SYSTEM WILL BE UNINTERRUPTED, ERROR-FREE, OR FREE OF
                HARMFUL COMPONENTS. SOME JURISDICTIONS DO NOT ALLOW THE
                EXCLUSIONS OF IMPLIED WARRANTIES. IN SUCH AN EVENT, THE ABOVE
                EXCLUSIONS WILL NOT APPLY SOLELY TO THE EXTENT PROHIBITED BY
                LAW.
              </p>

              <p>
                Please view additional Terms &amp; Conditions at{" "}
                <Link to="/legal/merchant-terms">
                  https://www.spoton.com/legal/merchant-terms
                </Link>
              </p>
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary 
                target="/legal/user-terms" 
                ctaTitle="User Terms" 
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
               <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default RestautantService;
